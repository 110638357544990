import React from 'react'
import {graphql} from 'gatsby'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../lib/helpers'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import ProjectPreviewGrid from '../components/project-preview-grid'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Carousel from '../components/carousel'
import Typography from '@material-ui/core/Typography';

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      title
      description
      address
      phone
      mobile
      email
      facebook
      keywords
      shop_image{
        asset {
          url
        }
      }
      logo {
        asset {
          url
        }
      }
    }
    products: allSanityProduct(
      limit: 6
      sort: {fields: [publishedAt], order: DESC}
      filter: { released: {eq: true }, slug: {current: {ne: null}}, publishedAt: {ne: null} }
    ) {
      edges {
        node {
          id
          photo {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
          publishedAt
          title
          slug {
            current
          }
        }
      }
    }
  }
`

const IndexPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const productNodes = (data || {}).products
    ? mapEdgesToNodes(data.products)
    .filter(filterOutDocsWithoutSlugs)
    .filter(filterOutDocsPublishedInTheFuture)
    : []

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout logo={site.logo.asset.url} address={site.address} telephone={site.phone} mobile={site.mobile} email={site.email} fblink={site.facebook}>
      <SEO title={site.title} keywords={site.keywords} />

      <Container style={{margin:'80px auto'}}>
        {site.shop_image.asset && (
          <Carousel image={site.shop_image.asset.url}/>
        )}
        {!site.shop_image.asset && (
          <Carousel />
        )}
        

        <Typography variant="h6" style={{fontWeight: 'bold', marginTop: 20}}>Ποιοί Είμαστε</Typography>
        <Typography variant="subtitle" style={{textAlign: 'justify', marginTop: 20, marginLeft: 'auto', marginRight: 'auto'}}>
          {site.description}
        </Typography>

        {/* {productNodes && (
          <ProjectPreviewGrid
            title='Νεες Παραλαβες'
            nodes={productNodes}
            logo={site.logo.asset.url}
            style={{margin: 'auto'}}
          />
        )} */}
      </Container>
    </Layout>
  )
}

export default IndexPage
